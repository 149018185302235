import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Card, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { BsFillPlayFill, BsFillPauseFill } from 'react-icons/bs';
import './Show.css';
import interviewsData from './interviews.json';

const Show = () => {
  const [transcriptData, setTranscriptData] = useState([]);
  const [selectedInterview, setSelectedInterview] = useState(interviewsData[0]);
  const audioRef = useRef(null);
  const timeMarkerInterval = 60; // Configurable time marker interval in seconds

  useEffect(() => {
    const loadTranscript = async () => {
      try {
        const transcriptModule = await import(`./${selectedInterview.transcriptFile}`);
        setTranscriptData(transcriptModule.default);
      } catch (error) {
        console.error(`Failed to load transcript file ${selectedInterview.transcriptFile}`, error);
      }
    };

    loadTranscript();
  }, [selectedInterview]);

  const playAudio = (start) => {
    if (audioRef.current) {
      audioRef.current.currentTime = start;
      audioRef.current.play();
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  const groupTranscriptBySpeaker = (transcript) => {
    const groupedTranscript = [];
    let currentSpeaker = null;
    let currentGroup = [];

    transcript.forEach((item) => {
      if (item.speaker !== currentSpeaker) {
        if (currentGroup.length > 0) {
          groupedTranscript.push({ speaker: currentSpeaker, items: currentGroup });
          currentGroup = [];
        }
        currentSpeaker = item.speaker;
      }
      currentGroup.push(item);
    });

    if (currentGroup.length > 0) {
      groupedTranscript.push({ speaker: currentSpeaker, items: currentGroup });
    }

    return groupedTranscript;
  };

  const handleSelect = (name) => {
    const interview = interviewsData.find((interview) => interview.name === name);
    setSelectedInterview(interview);
  };

  const formatInterviewName = (name) => {
    return name.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());
  };

  const formatTime = (seconds) => {
    const date = new Date(seconds * 1000);
    const minutes = date.getUTCMinutes();
    const secs = date.getUTCSeconds();
    return `${minutes}:${secs.toString().padStart(2, '0')}`;
  };

  let lastTimeMarker = -timeMarkerInterval;

  return (
    <Container className="mt-4 mb-4">
      <Row className="my-3">
        <DropdownButton
          id="dropdown-basic-button"
          title={`Select Interview: ${formatInterviewName(selectedInterview.name)}`}
          onSelect={handleSelect}
        >
          {interviewsData.map((interview, index) => (
            <Dropdown.Item key={index} eventKey={interview.name}>
              {formatInterviewName(interview.name)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </Row>
      <Row className="my-3">
        <Card className="mb-3 clickable-card" style={{ backgroundColor: '#F8F8F6', marginBottom: '0px', cursor: 'pointer' }}>
          <Card.Body style={{ padding: '15px', fontFamily: 'inherit' }}>
            <div className="transcript-section">
              {groupTranscriptBySpeaker(transcriptData).map((group, index) => {
                const showTimeMarker = group.items[0].start >= lastTimeMarker + timeMarkerInterval;
                if (showTimeMarker) lastTimeMarker = group.items[0].start;
                return (
                  <div key={index} className="transcript-speaker">
                    {showTimeMarker && (
                      <>
                        <span className="time-marker">{formatTime(group.items[0].start)}</span>
                        <Button variant="link" size="sm" onClick={() => playAudio(group.items[0].start)} style={{ color: 'black', marginRight: '10px' }}>
                          <BsFillPlayFill />
                        </Button>
                        <Button variant="link" size="sm" onClick={stopAudio} style={{ color: 'black', marginRight: '10px' }}>
                          <BsFillPauseFill />
                        </Button>
                      </>
                    )}
                    <span className="speaker-name">{group.speaker ? group.speaker.toUpperCase() : 'UNKNOWN'}:</span>
                    {group.items.map((item, subIndex) => (
                      <span key={subIndex} className="transcript-text"> {item.text}</span>
                    ))}
                  </div>
                );
              })}
            </div>
          </Card.Body>
        </Card>
      </Row>
      <audio ref={audioRef} src={`${process.env.PUBLIC_URL}/${selectedInterview.audioFile}`} />
    </Container>
  );
};

export default Show;
