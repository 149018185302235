import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer() {
  return (
    <footer className="bg-dark text-light mt-5">
      <Container>
        <Row className="py-3">
          <Col md={12} className="text-center">
            <p className="brand-logo">explore</p>
            <p>&copy; {new Date().getFullYear()} BuildGPT. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;