// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, NavLink, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Show from './Show';
import Footer from './Footer';

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  const [userState, setUserState] = useState({});

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar bg="light" variant="light">
        <Container>
          <Navbar.Brand as={NavLink} to="/" className="brand-logo">explore</Navbar.Brand>
        </Container>
      </Navbar>
      <Routes>
        <Route path="/" element={<Show userState={userState} setUserState={setUserState} />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
